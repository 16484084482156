import Section from './Section'
import Item from './sections/Item'
import { ISection, ISections } from '../types/Section'

function Main({ data }: ISections) {
  return (
    <main>
      {data.map((section: ISection) => (
        <Section key={section.id} {...section}>
          <Item id={section.id} />
        </Section>
      ))}
    </main>
  )
}

export default Main
