import React, { BaseSyntheticEvent } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import logo from '../images/logo.svg'
import { ISection, ISections } from '../types/Section'

function Navigation({ data }: ISections) {
  function handleClick(e: BaseSyntheticEvent) {
    e.preventDefault()
    let section = document.getElementById(e.target.hash.substring(1))
    section?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    })
  }
  return (
    <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="#">
          <img
            alt=""
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          Les Miels du Lysos
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {data.map((section: ISection) => (
              <Nav.Link
                key={section.id}
                href={`#${section.id}`}
                onClick={handleClick}
              >
                {section.title}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
