import React, { BaseSyntheticEvent, useState } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import '../../styles/Product.scss'
import { IProduct } from '../../types/Product'

function Product({
  image,
  title,
  texture,
  taste,
  region,
  description,
}: IProduct) {
  const [display, setDisplay] = useState(false)
  function toggle(e: BaseSyntheticEvent) {
    e.preventDefault()
    setDisplay(!display)
  }
  return (
    <Col md="4" className="Product">
      <Row>
        <Col>
          <div
            style={{
              position: 'absolute',
              height: '100%',
              left: 0,
              backgroundColor: 'rgba(49.8, 49.8, 44.3, 0.6)',
              color: 'rgb(254, 254, 226)',
              padding: '1rem',
              display: display ? 'block' : 'none',
            }}
            onPointerLeave={toggle}
          >
            <div
              style={{
                border: '1px solid rgb(254, 254, 226)',
                position: 'relative',
                padding: '1rem',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              {description}
            </div>
          </div>
          <Image src={image} fluid onPointerEnter={toggle} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="text-center" style={{ fontSize: '1.8rem' }}>
            {title}
          </h2>
          <p>
            {texture} | {taste} | {region}
          </p>
          {false ? <p className="text-justify">{description}</p> : null}
        </Col>
      </Row>
    </Col>
  )
}

export default Product
