import React from 'react'
import Head from './components/Head'
import Header from './components/Header'
import Footer from './components/Footer'
import Main from './components/Main'
import Navigation from './components/Navigation'
import { HelmetProvider } from 'react-helmet-async'
import sections from './content/sections.json'

function App() {
  return (
    <HelmetProvider>
      <Head useHelmet={false} />
      <Navigation data={sections} />
      <Header />
      <Main data={sections} />
      <Footer />
    </HelmetProvider>
  )
}

export default App
