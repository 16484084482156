import React, { BaseSyntheticEvent } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import '../../styles/Services.scss'
import servicesLeft from '../../images/services-left.jpg'
import servicesRight from '../../images/services-right.jpg'

function Services() {
  function setSubject(e: BaseSyntheticEvent) {
    let s = document.getElementById('Contact-subject')
    if (s) s.innerText = 'Demande de devis'
  }

  return (
    <Container className="Services">
      <Row id="pollinisation">
        <Col>
          <h1 className="text-left titlePaddingTop">Pollinisation</h1>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md="4">
          <Image alt="Pollinisation" src={servicesLeft} fluid />
        </Col>
        <Col md="8">
          <p>
            Les <b>miels du Lysos</b> proposent depuis plus de 10 ans un service
            de pollinisation aux agriculteurs semenciers.
          </p>
          <p>
            Notre service de pollinisation est tourné exclusivement vers les
            floraisons de colza et tournesol.
          </p>
          <p>
            Nous vous proposons d'évaluer avec vous ou votre technicien vos
            besoins en pollinisateurs en fonction de différents paramètres:
          </p>
          <ul>
            <li>Surface</li>
            <li>Floraisons</li>
            <li>Nombre de colonies</li>
            <li>Temps d'immobilisation</li>
          </ul>
          <p>
            Nous vous proposons également d'établir avec vous les lieux qui
            faciliteront l'efficacité organisationnelle.
          </p>
          <p>Devis sous 48h après réception du formulaire de demande.</p>
          <p>
            <a href="#contact" onClick={setSubject}>
              Demande de devis
            </a>
          </p>
          <Row style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <Col md={6}>
              <p>Nous nous engageons :</p>
              <ul>
                <li>
                  À respecter les dates de dépôt dans la mesure où le conseiller
                  agricole / agriculteur nous prévient une semaine à l'avance.
                </li>
                <li>
                  À laisser les ruches pendant une durée d'un mois à partir de
                  la date de dépôt.
                </li>
              </ul>
            </Col>
            <Col md={6}>
              <p>L'agriculteur s'engage :</p>
              <ul>
                <li>À nous prévenir une semaine avant le dépôt des ruches.</li>
                <li>
                  À n'utiliser aucun traitement pendant le durée du dépôt.
                </li>
                <li>À ne pas irriguer pendant la journée.</li>
              </ul>
            </Col>
          </Row>
          <p>
            Le service de pollinisation est proposé à partir d'une commande de 4
            ruches. Le nombre de ruches commandées se fera par multiple de 4 (1
            palette) pour facilité l'organisation.
          </p>
          <p>Le paiement du service se fera avant le dépôt des ruches.</p>
          <p>Nous nous engageons à fournir un service de qualité.</p>
        </Col>
      </Row>
      <Row id="essaims">
        <Col>
          <h1 className="text-right titlePaddingTop">Essaims</h1>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md="8">
          <p>
            Nous vendons des essaims hivernés disponibles pendant le mois
            d'avril, ainsi que des essaims de l'année pendant la saison.
          </p>
          <p>Nous proposons deux formats.</p>
          <p>Les 3 cadres:</p>
          <ul>
            <li>1 cadre de miel</li>
            <li>1 cadre de couvain ouvert</li>
            <li>1 cadre de couvain operculé</li>
          </ul>
          <p>Les 5 cadres:</p>
          <ul>
            <li>1 cadre de miel</li>
            <li>3 cadre de couvains</li>
            <li>1 cadre de étiré</li>
          </ul>
          <p>
            Les reines hivernées ainsi que les reines de l'année sont issues de
            sélections massales, c'est-à-dire dans un environnement saturé de
            mâles et de reines F1 sélectionnées.
          </p>
          <p>
            Les reines sont marquées de la couleur de l'année. Nous ne proposons
            aucune expédition d'essaims. Tous les essaims seront à récupérer en
            ruchette consignée ou directement dans vos ruches et ruchettes (le
            matériel devra être déposé 3 semaines avant récupération).
          </p>
          <p>
            <a href="#contact" onClick={setSubject}>
              Demande de devis
            </a>
          </p>
          <p>
            <i>Paiement de 30% à la réservation, le solde à l'enlèvement.</i>
          </p>
        </Col>
        <Col md="4">
          <Image alt="Essaimas" src={servicesRight} fluid />
        </Col>
      </Row>
    </Container>
  )
}

export default Services
