import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import '../styles/Header.scss'
import logo from '../images/header-logo.svg'

function Header() {
  return (
    <Container fluid className="Header" as="header">
      <Row>
        <Col className="text-center center-x-y">
          <Image src={logo} fluid />
          <h1>
            <span>Les miels du Lysos</span>
          </h1>
          <p>miels - pollinisation - essaims</p>
        </Col>
      </Row>
    </Container>
  )
}

export default Header
