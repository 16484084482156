import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import presentationLeft from '../../images/presentation-left.jpg'
import presentationRight from '../../images/presentation-right.jpg'

function Presentation() {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col>
          <p>
            La <b>ferme du Lysos</b> domine la vallée naturelle où coule le
            Lysos. Elle se situe à la frontière des sables et des coteaux de la
            Garonne, entre la flore landaise et les vallons du Lot-et-Garonne.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <p>
            Elle est nichée sur son coteau depuis 2005. Les apiculteurs qui y
            travaillent s'efforcent de travailler humblement, en prenant soin du
            développement de cet insecte social qu'est l'abeille.
          </p>
          <p>
            La ferme est le lieu central, la plateforme à partir de laquelle
            s'organisent et se rythment les journées.
          </p>
          <Image alt="Ruches" src={presentationLeft} fluid />
          <p>
            Nous sommes producteurs. Nous tenons à signaler que suivant les
            années le goût, la teinte et la gamme florale proposée évolue.
          </p>
          <p>
            Nous produisons et commercialisons également des{' '}
            <a href="#essaims">essaims</a> par le biais de partenaires locaux.
          </p>
          <p>
            Si vous êtes apiculteur de loisir, nous vous conseillons de vous
            adresser aux magasins suivants :
          </p>
          <ul>
            <li>
              <a
                href="https://www.apidistribution.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Apidistribution
              </a>{' '}
              pour le secteur bordelais
            </li>
            <li>
              <a
                href="https://www.icko-apiculture.com/reservation-essaims-cazeres"
                target="_blank"
                rel="noreferrer"
              >
                ICKO Cazères
              </a>{' '}
              pour le secteur toulousain
            </li>
          </ul>
          <p>
            Enfin, nous proposons un service de{' '}
            <a href="#pollinisation">pollinisation</a> pour les agriculteurs
            semenciers.
          </p>
        </Col>
        <Col md="6">
          <p>
            Nous sommes des <b>paysans nomade</b>. Cette ferme s'étend sur plus
            de 3000 km2 de relations (riverains, paysans, collègues). Elles se
            sont nouées dans le respect et la confiance.
          </p>
          <p>
            Un bout de forêt inoccupée, une bande enherbée, un terrain
            communal... Autant de lieux qui nous permettent de saisir des
            photographies gustatives de nos paysages girondins et landais.
          </p>
          <p>
            Nous produisons et commercialisons localement sous la marque{' '}
            <b>Les miels du Lysos</b>.
          </p>
          <p>
            <a href="#contact">Où nous trouver ?</a>
          </p>
          <Image alt="Apiculteur" src={presentationRight} fluid />
        </Col>
      </Row>
    </Container>
  )
}

export default Presentation
