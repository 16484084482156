import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import '../../styles/Contact.scss'
import axios from 'axios'

function Contact() {
  const [validated, setValidated] = useState(false)
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    note: '',
  })

  const handleSubmit = (event: any) => {
    const form = event.currentTarget
    if (!form.checkValidity()) {
      event.preventDefault()
      event.stopPropagation()
    }

    axios({
      method: 'post',
      url: 'contact.php',
      headers: { 'content-type': 'application/json' },
      data: {
        ...data,
        subject: document.getElementById('Contact-subject')?.innerText,
      },
    })
      .then((result: any) => {
        console.log(result)
      })
      .catch((error: any) => {
        console.log(error)
      })

    setValidated(true)
  }

  return (
    <Container>
      <Row>
        <Col lg={8} className="mx-auto">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicName">
              <Form.Label>Nom *</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Votre nom"
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
              <Form.Control.Feedback>Nom renseigné!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Veuillez saisir votre nom
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Courriel *</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Votre adresse email"
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
              <Form.Control.Feedback>Courriel renseigné!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Veuillez saisir un courriel valide
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicPhone">
              <Form.Label>Numéro de téléphone</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Votre numéro de téléphone"
                onChange={(e) => setData({ ...data, phone: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formBasicMessage">
              <Form.Label>Message *</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Votre message"
                as="textarea"
                rows={5}
                onChange={(e) => setData({ ...data, message: e.target.value })}
              />
              <Form.Control.Feedback>Message renseigné!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Veuillez saisir votre message
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group id="Contact-note" controlId="formBasicNote">
              <Form.Label>Note</Form.Label>
              <Form.Control
                type="text"
                placeholder="Une note"
                onChange={(e) => setData({ ...data, note: e.target.value })}
              />
            </Form.Group>
            <span id="Contact-subject">Contact</span>
            <Button variant="dark" type="submit">
              Envoyer
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Contact
