import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Values() {
  return (
    <Container>
      <Row>
        <Col className="text-justify">
          <h2>Ce qui nous porte : « L'autonomie »</h2>
          <ul>
            <li>L'utilisation de matières premières issues de notre ferme.</li>
            <li>La pratique de l' élevage jusqu'au travail du miel.</li>
            <li>
              L'accompagnement de « notre abeille » sur certains comportements.
            </li>
            <li>l' expérimentation et la recherche</li>
          </ul>
          <p>
            Nous recherchons en permanence de nouveaux lieux. Nous faisons
            évoluer nos pratiques et restons en lien avec le monde apicole pour
            évaluer, enrichir, développer nos pratiques et nos outils.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default Values
