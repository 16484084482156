import React from 'react'
import { Helmet } from 'react-helmet-async'
import image from '../images/sharing.jpg'

function HeadWithHelmet() {
  const lang = 'fr'
  const link = 'https://lesmielsdulysos.fr'
  const title = 'Les miels du Lysos'
  const description =
    'Producteurs de miels entre Gironde et Landes. Nous produisons du miel, des essaims, et proposons un service de pollinisation dans le Sud-Ouest.'
  return (
    <Helmet>
      <html lang={lang} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#32322c" />

      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={link} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${link}${image}`} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={link} />
      <meta property="og:locale" content={lang} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${link}${image}`} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="600" />
    </Helmet>
  )
}

type HeadProps = {
  useHelmet: boolean
}

function Head({ useHelmet }: HeadProps) {
  return useHelmet ? <HeadWithHelmet /> : null
}

export default Head
