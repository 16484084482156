import React from 'react'
import Contact from './Contact'
import Presentation from './Presentation'
import Products from './Products'
import Services from './Services'
import Team from './Team'
import Values from './Values'
import { IItem } from '../../types/Section'

function Item({ id }: IItem) {
  switch (id) {
    case 'farm':
      return <Presentation />
    case 'products':
      return <Products />
    case 'values':
      return <Values />
    case 'services':
      return <Services />
    case 'team':
      return <Team />
    case 'contact':
      return <Contact />
    default:
      return null
  }
}

export default Item
