import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Product from './Product'
import products from '../../content/products.json'

function Products() {
  return (
    <Container>
      <Row>
        <Col className="text-left">
          <h1 className="titlePaddingTop">Miels</h1>
          <hr />
          <p>Saison 2021 | Conditionnement 500 g</p>
        </Col>
      </Row>
      <Row>
        {products.map((product, k) => (
          <Product key={k} {...product} />
        ))}
      </Row>
      <hr />
      <Row className="justify-content-md-center">
        <Col>
          <p>
            <i>
              Imaginez une nuée d'abeilles et des apiculteurs travaillant
              méthodiquement. Une odeur de fumée de pins, un bruit de souffleur
              et un goût. Un goût sucré pris à la sauvette et manger à travers
              le voile. Ce miel encore tiède de la ruche.
            </i>
          </p>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <h2>La récolte</h2>
          <p>
            Privilégiés, nous ne pouvons que savourer cette bouchée distillée
            par les abeilles, ce nectar qui nous entoure à cet instant.
          </p>
          <p>
            Cet instant banal, qui ne devient un luxe que quand on y pense
            après...
          </p>
        </Col>
        <Col md="4">
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#32322c',
            }}
          ></div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md="4">
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#32322c',
            }}
          ></div>
        </Col>
        <Col md="8">
          <h2 className="text-right">L'extraction</h2>
          <p>Viendra par la suite le temps de l'extraction.</p>
          <p>
            Aux <b>miels du Lysos</b>, nous avons fait le choix d'utiliser du
            matériel d'extraction à froid. Ce miel sera filtré en fût et stocké
            en cave pour garantir la meilleure conservation avant sa mise en
            pots.
          </p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md="8">
          <h2 className="text-left">Mise en pots - Travail du miel</h2>
          <p>
            Naturellement, et avec le temps, le miels va s'épaissir. Des
            cristaux se développent.
          </p>
          <p>
            Suivant les miels, les sucres diffèrent et avec eux le <i>grain</i>{' '}
            du miel sera plus ou moins doux.
          </p>
          <p>
            À la ferme les <b>miels du Lysos</b>, nous faisons le choix, grâce à
            l'expérience acquise depuis de longues années, d'accompagner la
            cristallisation de nos miels.
          </p>
          <p>
            Ce choix nous permet de vous proposer un miel qui fond dans la
            bouche et agréable à tartiner.
          </p>
          <p>
            Le miel d'Acacia et de Bourdaine sont les deux seuls miels liquides
            de notre gamme.
          </p>
          <p>
            Pour vous proposer un produit de qualité toute l'année, nous
            distribuons au fur et à mesure dans les différents points de vente.
          </p>
        </Col>
        <Col md="4">
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#32322c',
            }}
          ></div>
        </Col>
      </Row>
    </Container>
  )
}

export default Products
