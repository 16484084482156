import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../styles/Section.scss'
import { IHeader, ISection } from '../types/Section'

function PicturedHeader({ title, image }: IHeader) {
  const style = {
    backgroundImage: `url(${image})`,
  }
  return (
    <Container fluid className="Section-header" style={style}>
      <Container fluid className="Section-header-overlay">
        <Row className="Section-header-h">
          <Col className="text-center">
            <h1 className="center-x-y">{title}</h1>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

function SimpleHeader({ title }: any) {
  return (
    <Container>
      <Row>
        <Col className="text-left">
          <h1>{title}</h1>
          <hr />
        </Col>
      </Row>
    </Container>
  )
}

function Section({ id, title, image, children }: ISection) {
  return (
    <section id={id} className="Section">
      {image ? (
        <PicturedHeader title={title} image={image} />
      ) : (
        <SimpleHeader title={title} />
      )}
      {children}
    </section>
  )
}

export default Section
