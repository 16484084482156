import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import '../styles/Footer.scss'

function Footer() {
  return (
    <Container
      className="Footer bg-dark text-center text-white"
      as="footer"
      fluid
    >
      <Row>
        <Col>Les miels du Lysos - {new Date().getFullYear()}</Col>
      </Row>
      <Row>
        <Col>
          <a
            href="https://www.instagram.com/mielsdulysos/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
