import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import '../../styles/Team.scss'
import team from '../../content/team.json'
import { ITeamMate } from '../../types/Team'

function TeamMate({ firstname, lastname, image, job, role }: ITeamMate) {
  return (
    <Col md={6} className="text-center">
      <Image src={image} />
      <h2>
        {firstname} {lastname}
      </h2>
      <p>{job}</p>
      <i>{role}</i>
    </Col>
  )
}

function Team() {
  return (
    <Container className="Team">
      <Row>
        {team.map((teammate) => (
          <TeamMate key={teammate.firstname} {...teammate} />
        ))}
      </Row>
    </Container>
  )
}

export default Team
